import {
  Injectable} from '@angular/core';
import {
  RestService
} from '../core/rest.service';
import {
  AppConfig
} from '../../config/app.config';
import { map } from 'rxjs/operators';

@Injectable()
export class IssuesService {

  constructor(private restService: RestService) {}
  public addIssue(data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues', data, false, true, true).pipe(map((res: any) => res));
  }
  public getIssues(id, no) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/issues/' + id + '/' + no, null).pipe(map((res: any) => res));
  }
  public getIssue(id) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/issues/' + id, null).pipe(map((res: any) => res));
  }
  public updateIssueDetails(data) {
    return this.restService.put(AppConfig.BASE_URL + '/editaware/issues', data, null, true, false).pipe(map((res: any) => res));
  }
  public getIssueMasterDocument(masterDocumentNodeId) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/issues/master/' + masterDocumentNodeId , null)
    .pipe(map((res: any) => res));
  }
  public getnodeDetails(nodeId) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/issues/nodeDetails/' + nodeId , null).pipe(map((res: any) => res));
  }
  public getChildnodeDetails(nodeId) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/issues/childNodesDetails/' + nodeId , null).pipe(map((res: any) => res));
  }
  public getIssueDescription(documentNodeId) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/issues/content/' + documentNodeId , null).pipe(map((res: any) => res));
  }
  public updateIssueDescription(documentNodeId, data) {
    return this.restService.put(AppConfig.BASE_URL + '/editaware/issues/content/' + documentNodeId, data, null, true, false).pipe(map((res: any) => res));
  }
  public addSection(sectionKey , nodeId, issueFolderNodeId, data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/' + sectionKey + '/subSection/' + nodeId + '/' + issueFolderNodeId , data, false, true, true).pipe(map((res: any) => res));
  }
  public updateSection(sectionKey, nodeId , data) {
    return this.restService.put(AppConfig.BASE_URL + '/editaware/issues/' + sectionKey + '/' + nodeId, data, false, true, false).pipe(map((res: any) => res));
  }
  public getLatestIssue(journalId) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/issues/latestIssue/' + journalId , null).pipe(map((res: any) => res));
  }
  public startArticleSelection(data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/startArticleSelection/' , data, null, null, true).pipe(map((res: any) => res));
  }
  public finalizeArticleSelection(masterXmlNodeId , data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/finalizeArticleSelection/' + masterXmlNodeId , data, null, null, true).pipe(map((res: any) => res));
  }
  public getIssueTaskData(id) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/issues/journalIssueDetailsByTask/' + id , null).pipe(map((res: any) => res));
  }
  public completeFinalizeArticleSlectionTask(key, masterNodeId, data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/completeFinalizeArticleSlectionTask/' + key + '/' + masterNodeId , data, null, true, true).pipe(map((res: any) => res));
  }
  public completeArticleSlectionFeedbackTask(key, data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/completeArticleSlectionFeedbackTask/' + key , data, null, true, true).pipe(map((res: any) => res));
  }
  public completeArticleSelectionTask(key, masterDocId, data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/completeArticleSelectionTask/' + key + '/' + masterDocId , data, null, true, true).pipe(map((res: any) => res));
  }
  public getEditors(journalId) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/group/editor/' + journalId, null).pipe(map((res: any) => res));
  }
  public getEdBoardMembers(journalId) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/group/edBoard/' + journalId, null).pipe(map((res: any) => res));
  }
  public getTypeSetters(journalId) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/group/typeSetters/' + journalId, null).pipe(map((res: any) => res));
  }
  public getGraphicDesigners(journalId) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/group/graphicDesigners/' + journalId, null).pipe(map((res: any) => res));
  }
  public startIssueSection(issueId, journalId , data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/startIssueSection/' + issueId + '/' + journalId , data, null, true, true).pipe(map((res: any) => res));
  }
  public getIssueSectionContent(id) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/issues/content/' + id, null).pipe(map((res: any) => res));
  }
  public saveIssueSectionContent(id, data) {
    return this.restService.put(AppConfig.BASE_URL + '/editaware/issues/content/' + id, data, null, true, true).pipe(map((res: any) => res));
  }
  public completeIssueSection(id, data) {
    return this.restService.put(AppConfig.BASE_URL + '/editaware/issues/completeIssueSection/' + id , data, null, true, true).pipe(map((res: any) => res));
  }
  public completeReviewIssueSection(Id , data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/completeReviewIssueSection/' + Id , data, null, true, true).pipe(map((res: any) => res));
  }
  public documentUpload(issueId, folderNodeId, formData) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/documentUpload/' + issueId + '/' + folderNodeId, formData, null, true, true).pipe(map((res: any) => res));
  }
  public documentUploadWithPageNumberDetails(articleNodeId, issueFolderName, issueFolderNodeId , masterXmlNodeId, formData) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/documentUploadWithPageNumberDetails/' + articleNodeId + '/' + issueFolderName + '/' + issueFolderNodeId + '/' + masterXmlNodeId, formData, null, true, true).pipe(map((res: any) => res));
  }
  public getUploadedImage(nodeId) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/issues/imagesource/' + nodeId + '/images', null).pipe(map((res: any) => res));
  }
  public deleteUploadedFile(nodeId) {
    return this.restService.delete(AppConfig.BASE_URL + '/editaware/issues/' + nodeId, true).pipe(map((res: any) => res));
  }
  public updateEstimatedDate(id, data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/updateIssueSection/' + id,  data, false, true, true).pipe(map((res: any) => res));
  }
  public startArticleTypesetting(journalName, data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/startArticleTypesetting/' + journalName,  data, false, true, true).pipe(map((res: any) => res));
  }
  public completeTransferRightsTask(id, data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/completeTransferRightsTask/' + id,  data, false, true, true).pipe(map((res: any) => res));
  }
  public completeRequestTypesettingInputTask(id, data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/completeRequestTypesettingInputTask/' + id,  data, false, true, true).pipe(map((res: any) => res));
  }
  public completeReviewTypeSettingInputTask(id, data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/completeReviewTypeSettingInputTask/' + id,  data, false, true, true).pipe(map((res: any) => res));
  }
  public completeAuthorDelegatedTask(id, data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/completeAuthorDelegatedTask/' + id,  data, false, true, true).pipe(map((res: any) => res));
  }
  public completeTypesettingTask(id, data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/completeTypesettingTask/' + id,  data, false, true, true).pipe(map((res: any) => res));
  }
  public completeAuthorReviewTask(id, data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/completeAuthorReviewTask/' + id,  data, false, true, true).pipe(map((res: any) => res));
  }
  public completeEditorReviewTask(id, data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/completeEditorReviewTask/' + id,  data, false, true, true).pipe(map((res: any) => res));
  }
  public completeFinalReviewTask(id, data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/completeFinalReviewTask/' + id,  data, false, true, true).pipe(map((res: any) => res));
  }
  public completeGenerateTypesetPdfTask(id, data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/completeGenerateTypesetPdfTask/' + id,  data, false, true, true).pipe(map((res: any) => res));
  }
  public delegateTask(id, userId, data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/delegateTask/' + id + '/' + userId,  data, false, true, true).pipe(map((res: any) => res));
  }
  public startIssueTypesetting(journalId, data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/startIssueTypesetting/' + journalId,  data, false, true, true).pipe(map((res: any) => res));
  }
  public completeRequestIssueTypesettingInputTask(id, data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/completeRequestIssueTypesettingInputTask/' + id,  data, false, true, true).pipe(map((res: any) => res));
  }
  public completeReviewIssueTypeSettingInputTask(id, data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/completeReviewIssueTypeSettingInputTask/' + id,  data, false, true, true).pipe(map((res: any) => res));
  }
  public completeIssueTypesettingTask(id, data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/completeIssueTypesettingTask/' + id,  data, false, true, true).pipe(map((res: any) => res));
  }
  public completeEditorOverallIssueReviewTask(id, data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/completeEditorOverallIssueReviewTask/' + id ,  data, false, true, true).pipe(map((res: any) => res));
  }
  public completeEdBoardOverAllIssueReview(id, data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/completeEdBoardOverAllIssueReview/' + id,  data, false, true, true).pipe(map((res: any) => res));
  }
  public completeGenerateOverallIssueTypesetPdfTask(id, data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/completeGenerateOverallIssueTypesetPdfTask/' + id ,  data, false, true, true).pipe(map((res: any) => res));
  }
  public completeOverallIssueFinalReviewTask(id, data) {
    return this.restService.post(AppConfig.BASE_URL + '/editaware/issues/completeOverallIssueFinalReviewTask/' + id ,  data, false, true, true).pipe(map((res: any) => res));
  }
  public getIssueTypesetDownloadZip(issueFolderName, issueNodeId , masterXmlNodeId) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/issues/issuetypeset/downloadzip/' + issueFolderName  + '/' + issueNodeId + '/' + masterXmlNodeId , null).pipe(map((res: any) => res));
  }
  public getArticleTypesetDownloadZip(articleFolderNodeId) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/issues/articletypeset/downloadzip/' + articleFolderNodeId, null).pipe(map((res: any) => res));
  }
}
