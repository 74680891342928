import { Injectable, Inject, EventEmitter } from '@angular/core';
import { RestService } from '../core/rest.service';
import { AppConfig } from '../../config/app.config';
import { map } from 'rxjs/operators';
@Injectable()
export class TaskService {

  constructor( private restService: RestService) { }

  public getTasks(search) {
    return this.restService.get( `${AppConfig.BASE_URL_V1}/journals/${AppConfig.JOURNAL_ID}/workflows`,search, null).pipe(map((res: any) => res));
  }
  public getTasKdetails(taskId) {
    return this.restService.get(`${AppConfig.BASE_URL_V1}/workflows/${taskId}`, true).pipe(map((res: any) => res));
  }
  startTask(articleId,data) {
    return this.restService.post(`${AppConfig.BASE_URL_V1}/journals/${AppConfig.JOURNAL_ID}/articles/${articleId}/workflows`,data,null).pipe(map((res: any) => res));
  }
  startPanelReviewTask(articleId,taskData) {
    return this.restService.post(`${AppConfig.BASE_URL_V1}/journals/${AppConfig.JOURNAL_ID}/articles/${articleId}/workflows`,taskData,null).pipe(map((res: any) => res));
  }
  completeTask(articleId,taskId,data) {
    return this.restService.post(`${AppConfig.BASE_URL_V1}/journals/${AppConfig.JOURNAL_ID}/articles/${articleId}/workflows/${taskId}/complete`,data, null).pipe(map((res: any) => res));
  }
  deleteTask(articleId,taskUUID) {
    return this.restService.delete(`${AppConfig.BASE_URL_V1}/journals/${AppConfig.JOURNAL_ID}/articles/${articleId}/reviews/${taskUUID}`, null).pipe(map((res: any) => res));
  }
  cancelTask(articleId,taskUUID) {
    return this.restService.put(`${AppConfig.BASE_URL_V1}/journals/${AppConfig.JOURNAL_ID}/articles/${articleId}/reviews/${taskUUID}/cancel`,{}, null).pipe(map((res: any) => res));
  }
  getWorkflowComment(articleId) {
    return this.restService.get(`${AppConfig.BASE_URL_V1}/journals/${AppConfig.JOURNAL_ID}/articles/${articleId}/comments`, null).pipe(map((res: any) => res));
  }
  public getPoolTasks(page) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/task/pooledTasks', { page }, null).pipe(map((res: any) => res));
  }
  public getPosterTasks(page, allTasks = false) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/task/poster/pooledTasks', { page, allTasks }, null).pipe(map((res: any) => res));
  }
  public getTaskData(id) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/taskDetails?taskId=' + id, true).pipe(map((res: any) => res));
  }
  public getTaskDataWithToken(id,token) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/taskDetails?taskId=' + id +'&token=' + token, true).pipe(map((res: any) => res));
  }
  public getPendingTasksList(taskId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/workflow/panelReview/pendingTask/' + taskId , true).pipe(map((res: any) => res));
  }

  public getTaskStatus(taskId,manuscriptId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/tasks/status/' + taskId +'/' +manuscriptId , true).pipe(map((res: any) => res));
  }
  public claimTask(taskId) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/abstract/task/claim/' + taskId , null).pipe(map((res: any) => res));
  }
  public unClaimTask(taskId) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/abstract/task/unClaim/' + taskId , null).pipe(map((res: any) => res));
  }
}
