import {
  Injectable} from '@angular/core';
import {
  Observable,
} from 'rxjs';

@Injectable()
export class CalendarUtilsService {
  public calenderLanguage: any;
  constructor() {}
  public getCalendarLocale(language: string): Observable<{}>  {
    switch (language) {
      case 'en-US':
        this.calenderLanguage = {
          firstDayOfWeek: 1,
          // tslint:disable-next-line:object-literal-sort-keys
          dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
          dayNamesShort: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
          dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
          // tslint:disable-next-line:max-line-length
          monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          monthNamesShort: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
          today: 'Today',
          clear: 'Delete',
        };
        return new Observable(this.calenderLanguage);
      case 'zh-Hant':
      this.calenderLanguage = {
        firstDayOfWeek: 1,
        dayNames: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
        dayNamesShort: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
        dayNamesMin: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
        monthNames: ['一月', '二月', '游行', '四月', '可以', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
        monthNamesShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        today: '今天',
        clear: '删除'
      };
      return new Observable(this.calenderLanguage);
      default:
        break;
    }
  }
}
