import { Injectable } from '@angular/core';
import { RestService } from '../core/rest.service';
import { AppConfig } from '../../config/app.config';
import { map } from 'rxjs/operators';
@Injectable()
export class ClientService {
  constructor(
    private restService: RestService) {
  }
  public getClients() {
    return this.restService
      .get(AppConfig.BASE_URL_V1 + '/client', true).
      pipe(map((res: any) => res));
  }
  public editClient(data , id) {
    return this.restService
      .put(AppConfig.BASE_URL_V1 + '/client/' + id, data).
      pipe(map((res: any) => res));
  }
  public addClient(values) {
    return this.restService
      .post(AppConfig.BASE_URL_V1 + '/client/', values).
      pipe(map((res: any) => res));
  }
}
