import {
  AbstractService
} from './abstract.service';
import {
  Injectable
} from '@angular/core';
import { map } from 'rxjs/operators';

import { AuthorDetails } from 'src/models/authorDetails.model';
import { TaskData } from 'src/models/task.model';
import { forkJoin } from 'rxjs';
import { of } from 'rxjs'
import { RestService } from 'src/core/rest.service';
import { AppConfig } from 'config/app.config';
// Service to Format and Get Response To Components
@Injectable()
export class WorkflowDataService {
  constructor(private abstractService: AbstractService,private restService: RestService) {}
  //   1. get task data
  // 2. get abstract content (wait) and format
  // 3. get author bio (wait) and create array
  //  4. Return formated task data
  public completeEditorReview(id, data) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/abstract/workflow/editorReview/' + id +'/complete', data, null).pipe(map((res: any) => res));
  }



  public formatAbstractDetails(taskData: TaskData) {
    let author = this.formatAuthorDetails(taskData.abstractDetail.authorDetails);
    let article = this.abstractService.getAbstractContent(taskData.abstractDetail.abstractMasterNodeId);
    let formatedData = {
      title: taskData.abstractDetail.abstractTitle,
      keywords: taskData.abstractDetail.keywords,
      type: taskData.abstractDetail.type
    };
    return forkJoin([of(formatedData), author, article]).pipe(map((res) => {
      let response = {
          title: res[0].title,
          keywords: res[0].keywords,
          type: res[0].type,
          abstract: res[2].response.content,
          authors: res[1]
      };
      return response;
  }));
  }
  public formatAuthorDetails(data) {
    let author = [];
    data.forEach((ele: AuthorDetails) => {
          author.push({
              title: ele.title,
              firstName: ele.firstName,
              middleName: ele.middleName,
              lastName: ele.lastName,
              designation: ele.designation,
              department: ele.department,
              isPresentor: ele.isPresentor,
              organization: ele.organization,
              email: ele.email,
              phone: ele.phone,
              address: ele.address
            });
  });
    return forkJoin(data.map(
      (ele) => {
          return this.abstractService.getAbstractContent(ele.bioContentNodeId);
      }
  )).pipe(map((el: any[]) => {
     let authorData =  el.map((element , i) => ({bio: element.response.content , ...author[i]}));
     return authorData;
  }));
}
}
