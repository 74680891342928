import {
  Injectable} from '@angular/core';

import {
  RestService
} from '../core/rest.service';
import {
  AppConfig
} from '../../config/app.config';
import { map } from 'rxjs/operators';

@Injectable()
export class EventsService {

  constructor( private restService: RestService) {}
 
  public createEvent(data, conferenceName) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/abstract/conferences/' + conferenceName + '/events', data, false, true, true).pipe(map((res: any) => res));
  }
  public getEvents( conferenceName) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/conferences/' + conferenceName + '/events', null ).pipe(map((res: any) => res));
  }
  public getEventDetails( conferenceName,eventName) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/conferences/' + conferenceName + '/events/' + eventName, null).pipe(map((res: any) => res));
  }
  public getEventDescription(id) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/event/' + id, null).pipe(map((res: any) => res));
  }
  public updateEventDetails(data, id, conferenceName) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/abstract/conferences/' + conferenceName + '/events/' + id, data, null).pipe(map((res: any) => res));
  }
  public updateEventDetailsSettings(data, id, conferenceName) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/abstract/conferences/' + conferenceName + '/events/' + id , data, null).pipe(map((res: any) => res));
  }

  public updateEventDetailsReviewSettings(data, id, conferenceName) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/abstract/conferences/' + conferenceName + '/events/' + id + '/reviewSetting', data, null).pipe(map((res: any) => res));
  }
  public deleteEvent(conferenceName, eventId) {
    return this.restService.delete(AppConfig.BASE_URL_V1 + '/abstract/conferences/' + conferenceName + '/events/' + eventId, null).pipe(map((res: any) => res));
  }
  public getIssueMasterDocument(masterDocumentNodeId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/issues/master/' + masterDocumentNodeId , null).pipe(map((res: any) => res));
  }
  public getnodeDetails(nodeId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/issues/nodeDetails/' + nodeId , null).pipe(map((res: any) => res));
  }
  public getChildnodeDetails(nodeId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/issues/childNodesDetails/' + nodeId , null).pipe(map((res: any) => res));
  }
  public getIssueDescription(documentNodeId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/issues/content/' + documentNodeId , null).pipe(map((res: any) => res));
  }
  public updateIssueDescription(documentNodeId, data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/issues/content/' + documentNodeId, data, null, false, true).pipe(map((res: any) => res));
  }
  public getAbstractCategories(conferenceName, eventName) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/categories/' + conferenceName + '/' + eventName, null).pipe(map((res: any) => res));
  }
  public createAbstractCategory(categoryName, conferenceName, eventName) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/abstract/categories/' + conferenceName + '/' + eventName, categoryName, false, true, true).pipe(map((res: any) => res));
  }
  public updateAbstractCategory(data, categoryId) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/abstract/categories/' + categoryId, data, false, true, false).pipe(map((res: any) => res));
  }
  public getPanels(category, conferenceName, eventName) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/panel/' + conferenceName + '/' + eventName + '/' + category, null).pipe(map((res: any) => res));
  }
  public getPanelDetails(panelName) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/panel/panelist/' + panelName, null).pipe(map((res: any) => res));
  }
  public updatePanelName(data, panelShortName) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/abstract/panel/' + panelShortName, data, false, true, false).pipe(map((res: any) => res));
  }
  public addPanelist(userId, panelshortName) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/abstract/panel/panelist/' + panelshortName + '/' + userId, null, false, true, true).pipe(map((res: any) => res));
  }
  public addIndividualReviewers(userId , userHomeId, conferenceShortName, eventShortName,  categoryName, categoryNodeId) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/abstract/panel/panelist/' + conferenceShortName + '/' + eventShortName + '/' + categoryName + '/' + categoryNodeId + '/' +  userId + '/' +  userHomeId, null, false, true, true).pipe(map((res: any) => res));
  }
  public deletePanelist(panelshortName,userId) {
    return this.restService.delete(AppConfig.BASE_URL_V1 + '/abstract/panel/panelist/' + panelshortName + '/' + userId, null).pipe(map((res: any) => res));
  }
  public removePanelistFromCategory(conferenceShortName, eventShortName, categoryName, categoryNodeId, userId) {
    return this.restService.delete(AppConfig.BASE_URL_V1 + '/abstract/panel/panelist/' + conferenceShortName + '/' + eventShortName + '/' + categoryName + '/' + categoryNodeId + '/' + userId, null).pipe(map((res: any) => res));
  }
  public createPanel(conferenceName, eventName, categoryShortName, panelName) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/abstract/panel/' + conferenceName + '/' + eventName + '/' + categoryShortName + '/' + panelName , null, null, true).pipe(map((res: any) => res));
  }
  public addAdministratorToEvent(conferenceName, eventName, userId) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/abstract/group/administrator/' + conferenceName + '/' + eventName + '/' + userId , null, null, true).pipe(map((res: any) => res));
  }
  public addCopyEditorToEvent(conferenceName, eventName, userId) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/abstract/group/copyeditor/' + conferenceName + '/' + eventName + '/' + userId , null, null, true).pipe(map((res: any) => res));
  }
  public getAdministratorForEvent(conferenceName, eventName) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/group/administrator/' + conferenceName + '/' + eventName, null).pipe(map((res: any) => res));
  }
  public getCopyEditorsForEvent(conferenceName, eventName) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/group/copyeditor/' + conferenceName + '/' + eventName, null).pipe(map((res: any) => res));
  }
  public deleteAdministrator(conferenceName, eventShortName , userId) {
    return this.restService.delete(AppConfig.BASE_URL_V1 + '/abstract/group/administrator/' + conferenceName + '/' + eventShortName + '/' + userId, null).pipe(map((res: any) => res));
  }
  public deleteCopyeditor(conferenceName, eventShortName , userId) {
    return this.restService.delete(AppConfig.BASE_URL_V1 + '/abstract/group/copyeditor/' + conferenceName + '/' + eventShortName + '/' + userId, null).pipe(map((res: any) => res));
  }
  public deleteUploadedFile(nodeId) {
    return this.restService.delete(AppConfig.BASE_URL_V1 + '/issues/' + nodeId, true).pipe(map((res: any) => res));
  }
  public deleteCategory(categoryNodeId) {
    return this.restService.delete(AppConfig.BASE_URL_V1 + '/abstract/categories/' + categoryNodeId, true).pipe(map((res: any) => res));
  }
}
