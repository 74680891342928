import { Location } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart , Router  } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/services/auth.service';
import { CommunicationService } from 'src/services/communication.service';
import { LoaderService } from '../core/loader/loader.service';
import { RestService } from '../core/rest.service';
import { I18nService } from 'src/core/i18n.service';


declare let ga: any;
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
  
})
export class AppComponent implements OnInit {
  public defaultLanguage: string;
  public supportedLanguages: any;
  public showFooter = true;
  public loaderService: LoaderService;
  constructor(private location: Location ,public i18nService: I18nService,
              public cookieService: CookieService,
              private router: Router, loaderService: LoaderService, public restService: RestService,
              public communicationService: CommunicationService, public authService:AuthService) {
      this.loaderService = loaderService;
      this.router.events.subscribe((event) => {
        if ((event) instanceof RouteConfigLoadStart) {
          this.loaderService.show();
        } else if ((event) instanceof RouteConfigLoadEnd) {
          this.loaderService.hide();
        }
        // if (event instanceof NavigationEnd) {
        //   ga('set', 'page', event.urlAfterRedirects);
        //   ga('send', 'pageview');
        // }
    });
      this.restService.forceLogout.subscribe((value) => {
      if (value === true) {
        this.cleanAndLogout();
      }
    });
      this.defaultLanguage = 'en-US';
      this.supportedLanguages = ['en-US', 'zh-Hant' ];
  }

  public ngOnInit() {
    this.i18nService.init(this.defaultLanguage, this.supportedLanguages);
    if (this.location.path().startsWith('/ckeditorLineComment')) {
      this.showFooter = false;
    }
  }
  public cleanAndLogout() {
    this.cookieService.deleteAll();
    sessionStorage.clear();
    this.communicationService.onlogoutUser();
    if (this.router.url !== '/iadvl') {
      this.cookieService.set('lastUrl', this.router.url);
    }
    this.router.navigate(['/iadvl']);
   }
}
