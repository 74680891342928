// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  AWS_ACCESS_KEY_ID: "AKIASN3VGEMO72JFLEUB",
  AWS_SECRET_ACCESS_KEY: "snwSiKUpvfLGpt+KsssvWOGOWXe2kDmN7lcMPSZB",
  AWS_REGION: "ap-south-1"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
