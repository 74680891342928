import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'iadvl' },
  { path: 'iadvl', component: LoginComponent },
  { path: 'forgotpassword', loadChildren: () => import('./forgotpassword/forgotpassword.module').then( (m) => m.ForgotPasswordModule) },
  { path: 'iadvl/account', loadChildren: () => import('./user/profileupdate/account.module').then( (m) => m.AccountModule) },
  { path: 'iadvl/mytasks', loadChildren: () => import('./home/task.module').then( (m) => m.TaskModule) },
  { path: 'iadvl/admin', loadChildren: () => import('./admin/admin.module').then( (m) => m.AdminModule) },
  { path: 'iadvl/application', loadChildren: () => import('./workflow/workflow/workflow.module').then( (m) => m.WorkflowModule) },
]; 

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
