import { Injectable } from '@angular/core';
import { RestService } from '../core/rest.service';
import { AppConfig } from '../../config/app.config';
import { map } from 'rxjs/operators';

@Injectable()
export class AbstractService {

  constructor(private restService: RestService) { }


  public getAbstractDetails(conferenceShortName, eventShortName, manuscriptId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/conferences/' + conferenceShortName + '/events/' + eventShortName + '/' + manuscriptId, true).pipe(map((res: any) => res));
  }
  public getAttachments(name) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/attachments/ilc/ilc-2022/' + name , true).pipe(map((res: any) => res));
  }
  public getAbstractDownload(nodeId) {
    return this.restService
      .get(AppConfig.BASE_URL_V1 + '/abstract/content/download/' + nodeId, null, false, true, true).
      pipe(map((res: any) => res));
  }

  public completeEditorReview(taskId, data) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/workflow/editorReview/' + taskId + '/complete', data,  false).pipe(map((res: any) => res));
  }

  public completeReviseManuscript(taskId, data) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/workflow/reviseManuscript/' + taskId + '/complete', data,  false).pipe(map((res: any) => res));
  }

  public completeCopyEdit(taskId, data) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/workflow/copyedit/' + taskId + '/complete', data,  false).pipe(map((res: any) => res));
  }

  public completeCEAuthorReview(taskId, data) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/workflow/copyedit/authorReview/' + taskId + '/complete', data,  false).pipe(map((res: any) => res));
  }

  public completeSingleReview(taskId, data) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/workflow/singleReview/' + taskId + '/complete', data,  false).pipe(map((res: any) => res));
  }

  public completePanelReview(taskId, data) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/abstract/workflow/panelReview/' + taskId + '/complete', data).pipe(map((res: any) => res));
  }

  public getAbstractContent(nodeId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/' + nodeId + '/content', null).pipe(map((res: any) => res));
  }
  public getReviewDetails(manuscriptId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/reviewerResponse/' + manuscriptId , null).pipe(map((res: any) => res));
  }
  public putAbstractContent(nodeId, content) {
    return this.restService.put(AppConfig.BASE_URL_V1 +  '/abstract/' + nodeId + '/content', content, false, false, true).pipe(map((res: any) => res));
  }
  public getEventGroupMembers(groupName, coferenceShortName, eventShortName) {
    return this.restService.get(AppConfig.BASE_URL_V1 +  '/abstract/group/' + groupName + '/' + coferenceShortName + '/' + eventShortName , null).pipe(map((res: any) => res));
  }
  
  // OLD API DETAILS

  public getArticles(page, userId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article', { page: page, userId: userId }, null).pipe(map((res: any) => res));
  }
  
  public saveAsDraft(data, conferenceName, eventName) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/abstract/conferences/' + conferenceName + '/events/' + eventName + '/draft', data, null, true, true).pipe(map((res: any) => res));
  }

  public createAbstract(data, conferenceName, eventName) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/abstract/external/conferences/' + conferenceName + '/events/' + eventName, data, null).pipe(map((res: any) => res));
  }

  public getAllArticleVersions(id) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article/versions/' + id, true).pipe(map((res: any) => res));
  }
  public getAllSubmittedAbstracts(data) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract',data, null).pipe(map((res: any) => res));
  }
  public saveArticleContent(nodeId, content, majorVersion) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/article/content/' + nodeId + '?majorVersion=' + majorVersion, content, false, false, true).pipe(map((res: any) => res));
  }
  public getArticleContent(nodeId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article/content/node/' + nodeId, null, false, false, true).pipe(map((res: any) => res));
  }

  public getArticleContentByVersion(nodeId, ver) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article/versionContent/' + nodeId + '/' + ver, null, false, false, true).pipe(map((res: any) => res));
  }
 
  public getArticleDiffContentByVersion(nodeId, selectedVer, Diffver) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article/htmlFileDifference/' + nodeId + '/' + selectedVer + '/' + Diffver, null, false, false, true).pipe(map((res: any) => res));
  }

  public saveAuthorsAndInvitedAuthors(id, data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/article/' + id, data).pipe(map((res: any) => res));
  }

  public updateArticleProperties(id, data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/article/' + id, data, true, true, true).pipe(map((res: any) => res));
  }

  public uploadAttachment(journalId, articleId, documentType, data) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/article/articleUploads/' + journalId + '/' + articleId + '/' + documentType, data, false, true, true).pipe(map((res: any) => res));
  }

  public submitArticle(journalId, articleId, documentId, data) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/article/submit/' + journalId + '/' + articleId + '/' + documentId, data, false).pipe(map((res: any) => res));
  }
 
  public submitArticleForPeerReview(taskId, data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/article/completePrAssignReviewers/' + taskId, data).pipe(map((res: any) => res));
  }

  public reSubmitArticle(taskId, data, documentId) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/article/revisedArticleSubmit/' + taskId + '/' + documentId, data).pipe(map((res: any) => res));
  }

  public peerReviewReSubmitArticle(taskId, data, documentId) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/article/peerReviewRevisedArticleSubmit/' + taskId + '/' + documentId, data).pipe(map((res: any) => res));
  }

  public PeerReviewInvitationSubmit(taskId, data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/article/completePRInvitationDecision/' + taskId, data).pipe(map((res: any) => res));
  }

  public actualPeerReviewSubmit(taskId, data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/article/completePROverrideInvitationDecision/' + taskId, data).pipe(map((res: any) => res));
  }
  public updatePRPeerReviewTask(taskId, data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/article/updatePRPeerReviewTask/' + taskId, data).pipe(map((res: any) => res));
  }

  public completePRPeerReviewTask(taskId, data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/article/completePRPeerReviewTask/' + taskId, data).pipe(map((res: any) => res));
  }

  public completePRReReview(taskId, data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/article/completePRReReview/' + taskId, data).pipe(map((res: any) => res));
  }

    public savePRConsolidateReviewTask(taskId, data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/article/savePRConsolidateReviewTask/' + taskId, data).pipe(map((res: any) => res));
  }  
  public completePRConsolidateReviewTask(taskId, data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/article/completePRConsolidateReviewTask/' + taskId, data, null).pipe(map((res: any) => res));
  }

  public completePREditorReview(taskId, data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/article/completePREditorReview/' + taskId, data, null).pipe(map((res: any) => res));
  }

  public completePRAssignCopyeditorTask(taskId, data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/article/completePRAssignCopyeditorTask/' + taskId, data).pipe(map((res: any) => res));
  }

  public completePRCopyEditTask(taskId, data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/article/completePRCopyEditTask/' + taskId, data).pipe(map((res: any) => res));
  }

  public completePRCEAuthorReviewTask(taskId, data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/article/completePRCEAuthorReviewTask/' + taskId, data).pipe(map((res: any) => res));
  }

  
  public completePRFinalReviewTask(taskId, data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/article/completePRFinalReviewTask/' + taskId, data).pipe(map((res: any) => res));
  }

  public completePRCEEditorReview(taskId, data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/article/completePRCEEditorReview/' + taskId, data).pipe(map((res: any) => res));
  }

  public getArticlesForJournal(pageNmber, journalId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article', { page: pageNmber, journalId: journalId }, true).pipe(map((res: any) => res));
  }
  public uploadManuscript(manuscriptNodeId, data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/article/uploadArticleManuscript/' + manuscriptNodeId, data, false, true, true).pipe(map((res: any) => res));
  }
  public deleteAuthor(userId, nodeId) {
    return this.restService.delete(AppConfig.BASE_URL_V1 + '/article/colloborator/' + userId + '/' + nodeId, true).pipe(map((res: any) => res));
  }
  public updateAuthor(userId, nodeId) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/article/colloborator/' + userId + '/' + nodeId, true, true, true).pipe(map((res: any) => res));
  }
  public getArticlePdf(articleId, nodeId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article/download/' + articleId + '/' + nodeId, null, true, true, true).pipe(map((res: any) => res));
  }

  public startInitialReviewOnClick(articleId, journalId) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/article/startInitialReview/' + articleId + '/' + journalId, true).pipe(map((res: any) => res));
  }
  public startPeerReviewOnClick(articleId, journalId) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/article/startPeerReview/' + articleId + '/' + journalId, true).pipe(map((res: any) => res));
  }

  public getPlagiarismDetails(nodeId, articleId, journalId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article/palagrism/' + nodeId + '/' + articleId + '/' + journalId, null, true).pipe(map((res: any) => res));
  }

  public getPlagiarismDetailsFromAuthor(maserXmlNodeId, articleNodeId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article/plagiarism/' + maserXmlNodeId + '/' + articleNodeId, null, true).pipe(map((res: any) => res));
  }
  public getPlagiarismForEditors(maserXmlNodeId, articleNodeId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article/plagiarismForEditors/' + maserXmlNodeId + '/' + articleNodeId, null, true).pipe(map((res: any) => res));
  }
  public getPlagiarismReport(plagiarismReportId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article/getPlagiarismReport/' + plagiarismReportId, false).pipe(map((res: any) => res));
  }
  public searchArticle(keyword, journalId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article', { keywords: keyword, journalId: journalId }, true).pipe(map((res: any) => res));
  }
  public uploadArticleImage(journalId, nodeName, type, data) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/uploadCKEditorImages?journalId=' + journalId + '&nodeName=' + nodeName + '&type=' + type, data, null, true, true).pipe(map((res: any) => res));
  }
  public getArticleAssets(journalId, nodeId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/assestsDetails', { journalId: journalId, nodeId: nodeId }, null).pipe(map((res: any) => res));
  }
  public getArticleAssetz(journalId, nodeId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/assestsDetails', { journalId: journalId, nodeId: nodeId }, null).pipe(map((res: any) => res));
  }
  public postComment(journalId, articleId, data) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/article/comments' + '/' + journalId + '/' + articleId, data).pipe(map((res: any) => res));
  }
  public getComments(journalId, articleNodeId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article/comments' + '/' + journalId + '/' + articleNodeId, null).pipe(map((res: any) => res));
  }
  public getImageSource(nodeId, fileName) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article/imagesource/' + nodeId + '/' + fileName, null, false,false, true).pipe(map((res: any) => res));
  }
  public changeArticleStatus(nodeId, status) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/article/status/' + status + '/' + nodeId, true, true, true).pipe(map((res: any) => res));
  }
  public getBibTex( blibText) {
    let data = {
      blibText: blibText
    };
    return this.restService.post(AppConfig.BASE_URL_V1 + '/api/fetchCitationFromBibtex', data).pipe(map((res: any) => res));
  }
  public getGeneratedReference(pubId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article/pubmed/generateReference/' + pubId, null).pipe(map((res: any) => res));
  }
  public getArticleDownload(nodeId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article/content/download/' + nodeId, null, false, true, true).pipe(map((res: any) => res));
  }
  public articleContentLockMode(nodeId, userId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article/editArticleContent' + '/' + nodeId + '/' + userId, null, false, false, true).pipe(map((res: any) => res));
  }
  public articleContentUnlockMode(nodeId) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/article/unlockArticle' + '/' + nodeId, null).pipe(map((res: any) => res));
  }
  public articleLockStatus(nodeId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article/nodeDetails' + '/' + nodeId, true).pipe(map((res: any) => res));
  }
  public getDashboardArticleReports(journalId, type) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article/dashboardArticleReports?journalId=' + journalId + '&' + type + '=true', true).pipe(map((res: any) => res));
  }
  public getDashboardUserReports(type) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article/dashboardUserReports?' + type + '=true', true).pipe(map((res: any) => res));
  }
  public getPdf(data) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/article/getpdf', data, null, true, true, true).pipe(map((res: any) => res));
  }
  public getAcceptedArticles(journalName) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/issues/searchAcceptedArticles/' + journalName, null).pipe(map((res: any) => res));
  }
  public searchAcceptedArticles(journalName, key) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/issues/searchArticles/' + journalName + '/' + key, null).pipe(map((res: any) => res));
  }
  public getAllLineCommentsWithReplies(articleNodeId, cycle, userId?, taskId?) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article/comments/getAllCommentsWithReplies/' + articleNodeId + '/' + cycle, { userId, taskId }, null).pipe(map((res: any) => res));
  }

  public getAllLineCommentsWithRepliesForPeerReReview(articleNodeId, cycle, userId? , userId2?) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article/comments/getAllCommentsWithReplies/' + articleNodeId + '/' + cycle, { userId: userId + ',' + userId2 }, null).pipe(map((res: any) => res));
  }
  public getAllCELineCommentsWithReplies(articleNodeId, cycle, userId?, taskId?) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article/comments/copyEdit/getAllCommentsWithReplies/' + articleNodeId + '/' + cycle, { userId, taskId }, null).pipe(map((res: any) => res));
  }
  public commentsCreateReply(topicNodeId, topicName, postName, data) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/article/comments/createReply/' + topicNodeId + '/' + topicName + '/' + postName, data, false).pipe(map((res: any) => res));
  }
  public commentsCreatePost(articleNodeId, data) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/article/comments/createPost/' + articleNodeId, data, false).pipe(map((res: any) => res));
  }
  public commentsCECreatePost(articleNodeId, data) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/article/comments/copyEdit/createPost/' + articleNodeId, data, false).pipe(map((res: any) => res));
  }
  public deleteLineComment(nodeId) {
    return this.restService.delete(AppConfig.BASE_URL_V1 + '/issues/' + nodeId, true).pipe(map((res: any) => res));
  }
  public updatePost(versionNodeId, systemName, content) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/article/comments/updatePost/' + versionNodeId + '/' + systemName, content, false, false, true).pipe(map((res: any) => res));
  }
  public deleteAcceptedPRReviewer(taskId, userId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article/deleteAcceptedPRReviewer/' + taskId + '/' + userId , null , false).pipe(map((res: any) => res));
  }
  public getEditors(journalId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/group/editor/' + journalId, null).pipe(map((res: any) => res));
  }
  public reassignTask(taskId, userId, articleNodeId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article/reassignTask/' + taskId + '/' + userId + '/' + articleNodeId, null).pipe(map((res: any) => res));
  }
  public getTotalNumberOfComments(articleNodeId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article/comments/getTotalNumberOfComments/' + articleNodeId, null).pipe(map((res: any) => res));
  }
  public getTotalNumberOfCommentsByUserId(articleNodeId, userId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article/comments/getTotalNumberOfComments/' + articleNodeId, {userId}).pipe(map((res: any) => res));
  }
  public getTotalNumberOfCEComments(articleNodeId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/article/comments/copyedit/getTotalNumberOfComments/' + articleNodeId, null).pipe(map((res: any) => res));
  }
  public sendEmails(nodeId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/mail/send/' + nodeId, null).pipe(map((res: any) => res));
  }
  public updateRejectStatus(nodeId,data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/abstract/status/reject/' + nodeId, data, true, true, true).pipe(map((res: any) => res));
  }
  public updateApproveStatus(nodeId,data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/abstract/status/approve/' + nodeId, data, true, true, true).pipe(map((res: any) => res));
  }
  public updateApproveWithModificationStatus(nodeId,data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/abstract/status/approveWithModification/' + nodeId, data, true, true, true).pipe(map((res: any) => res));
  }
  public updateUnderReviewStatus(nodeId,data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/abstract/underReview/reject/' + nodeId, data, true, true, true).pipe(map((res: any) => res));
  }
  public sendDecisionEmails(nodeId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/mailAndStatus/send/' + nodeId, null).pipe(map((res: any) => res));
  }
  public updateAbstract(nodeId,data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/abstract/external/conferences/' + nodeId, data, null).pipe(map((res: any) => res));
  }
  public modifyDecision(nodeId,data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/abstract/admin/modify/status/' + nodeId, data, null).pipe(map((res: any) => res));
  }
  public acceptInvitation(taskId,data) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/abstract/workflow/panelReview/accept/invitationDecision/' + taskId, data, null).pipe(map((res: any) => res));
  }
  public declineInvitation(taskId,data) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/abstract/workflow/panelReview/decline/invitationDecision/' + taskId, data, null).pipe(map((res: any) => res));
  }
  public downloadSubmissionInfo(identifier,htmlContent) {
    return this.restService
      .post(`${AppConfig.BASE_URL_V1}/abstract/download/${identifier}`, htmlContent, null, true, true, true).
      pipe(map((res: any) => res));
  }
  public downloadAbstractAsExcel(filterData) {
    return this.restService
      .get(`${AppConfig.BASE_URL_V1}/abstract/export`,filterData, false, true, true).
      pipe(map((res: any) => res));
  }
  public downloadPostersAsExcel(filterData) {
    return this.restService
      .get(`${AppConfig.BASE_URL_V1}/abstract/poster/export`,filterData, false, true, true).
      pipe(map((res: any) => res));
  }
  public getPreviousSubmissionDetails(conferenceShortName, eventShortName, manuscriptId,cycle) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/conferences/' + conferenceShortName + '/events/' + eventShortName + '/' + manuscriptId + '/history?cycle='+ cycle , true).pipe(map((res: any) => res));
  }
  public getPreviousReviewDetails(manuscriptId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/reviewerResponse/' + manuscriptId +'/history', null).pipe(map((res: any) => res));
  }
  public getRegistrationDetails() {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/feedRegistrationDetails', null).pipe(map((res: any) => res));
  }
  public getPreviousVersionDetails(nodeId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/abstractDetails/' +nodeId + '/previousVersion', null).pipe(map((res: any) => res));
  }
  public getAllPosters(data) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/poster',data, null).pipe(map((res: any) => res));
  }
  public getPosterPanelList(){
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/panel/poster/panelist', null).pipe(map((res: any) => res));
  }
  public getPosterDetails(posterId){
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/poster/' +posterId, null).pipe(map((res: any) => res));
  }
  public updatePosterStatus(posterId,data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/abstract/poster/status/' + posterId , data,  false).pipe(map((res: any) => res));
  }
  public completePosterReview(taskId, data) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/abstract/workflow/posterReview/' + taskId + '/complete', data).pipe(map((res: any) => res));
  }
  public getPosterPlayer(){
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/posterPlayer/device', null).pipe(map((res: any) => res));
  }
  public updatePosterMaping(deviceId,data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/abstract/posterPlayer/mapping/' + deviceId , data,  false).pipe(map((res: any) => res));
  }
  public getMappedAbstracts(deviceId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/posterPlayer/mapping/' + deviceId, null).pipe(map((res: any) => res));
  }
  public deletePosterPlayermapping(deviceId,PosterNodeId) {
    return this.restService.delete(AppConfig.BASE_URL_V1 + '/abstract/posterPlayer/mapping/' + deviceId + '/' + PosterNodeId, null).pipe(map((res: any) => res));
  }
  addPostertotheDevice(deviceId,PosterNodeId) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/abstract/posterPlayer/mapping/' + deviceId + '/' + PosterNodeId,  null).pipe(map((res: any) => res));
  }
  public getPosterMessages(PosterId){
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/posterPlayer/message/' + PosterId, null).pipe(map((res: any) => res));
  }
  public submitAbstract(formData) {
    return this.restService
      .post(AppConfig.BASE_URL_V1 + '/abstract/isgcon/conferences/isgcon/events/isgcon-2024', formData, null, true, false).
      pipe(map((res: any) => res));
  }
  public updateMyAbstract(abstractNodeId,formData) {
    return this.restService
      .post(AppConfig.BASE_URL_V1 + '/abstract/isgcon/' + abstractNodeId, formData, null, true, false).
      pipe(map((res: any) => res));
  }
  public getWorkflowComment(manuscriptId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/isgcon/workflow/comments/' + manuscriptId, true).pipe(map((res: any) => res));
  }
  public deleteVideoAbstract(abstractNodeId) {
    return this.restService.delete(AppConfig.BASE_URL_V1 + '/abstract/isgcon/delete/video/' + abstractNodeId, true).pipe(map((res: any) => res));
  }
  public getServerDate() {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/time', true).pipe(map((res: any) => res));
  }
}
