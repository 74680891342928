import { Injectable } from '@angular/core';
import { RestService } from '../core/rest.service';
import { AppConfig } from '../../config/app.config';
import { map } from 'rxjs/operators';

@Injectable()
export class ConferenceService {
  constructor( private restService: RestService) {
  }

 public getAbstracts(pageNumber) {
    return this.restService.get(AppConfig.BASE_URL_V1  + '/abstract/conferences', {pageNumber}).pipe(map((res: any) => res));
  }
  public getConference(conferenceName) {
    return this.restService.get(AppConfig.BASE_URL_V1  + '/abstract/conferences/' + conferenceName, null).pipe(map((res: any) => res));
  }
  public getConferenceDetails(id) {
    return this.restService.get(AppConfig.BASE_URL_V1  + '/abstract/' + id + '/content', true).pipe(map((res: any) => res));
  }
  public getJournalImage(id) {
    return this.restService.get(AppConfig.BASE_URL_V1  + '/journal/imagesource/' + id, true).pipe(map((res: any) => res));
  }
  public addConference(formData) {
    return this.restService.post(AppConfig.BASE_URL_V1  + '/abstract/conferences', formData, null, true, true).pipe(map((res: any) => res));
  }
  public updateConference(data, id) {
    return this.restService.put(AppConfig.BASE_URL_V1  + '/abstract/conferences/' + id, data).pipe(map((res: any) => res));
  }
  public updateConferenceDetails(data, id) {
    return this.restService.put(AppConfig.BASE_URL_V1  + '/abstract/' + id + '/content', data, false, false, true).pipe(map((res: any) => res));
  }
  public updateJournalImage(formData, id) {
    return this.restService.put(AppConfig.BASE_URL_V1  + '/journal/thumbnail/' + id, formData, null, true, true).pipe(map((res: any) => res));
  }
  public getEmailTemplateById(emailTemplateNodeId) {
    return this.restService.get(AppConfig.BASE_URL_V1  + '/journal/email/templates/' + emailTemplateNodeId, true).pipe(map((res: any) => res));
  }
  public getAllEmailTemplates() {
    return this.restService.get(AppConfig.BASE_URL_V1  + '/journal/email/templates', true).pipe(map((res: any) => res));
  }
  public updateEmailTemplate(emailTemplateNodeId, formData) {
    return this.restService.put(AppConfig.BASE_URL_V1  + '/journal/email/templates/' + emailTemplateNodeId, formData, true, true, true).pipe(map((res: any) => res));
  }
  public addEmailTemplate( formData) {
    return this.restService.post(AppConfig.BASE_URL_V1  + '/journal/email/templates', formData, null, true, true).pipe(map((res: any) => res));
  }
  public getHelpDocuments() {
    return this.restService.get(AppConfig.BASE_URL_V1  + '/help/getDocuments', null).pipe(map((res: any) => res));
  }
  public uploadHelpDocuments(name , formData) {
    return this.restService.post(AppConfig.BASE_URL_V1  + '/help/uploadDocuments/?name=' + name, formData, null, true, true).pipe(map((res: any) => res));
  }

}
