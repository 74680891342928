import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafePipe } from 'src/utils/safe.pipe';

// import { DragDropDirective } from 'directives/dragdrop.directive';
// import { TextUpperCaseDirective } from 'directives/textcapitalize.directive';

@NgModule({
   declarations: [SafePipe] ,
  exports: [
    // DragDropDirective,
    // TextUpperCaseDirective,
    // ModalModule,
    SafePipe,
    FormsModule,
    ReactiveFormsModule,
  ],
  imports: [
    // ModalModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [DatePipe],
})
export class SharedModule  { }
