<div [class.loader-hidden]="!show">
  <div class="loader-top-overlay">
    <div class="loader-overlay">
      <div *ngIf="show" class="loader">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
        <div class="bar4"></div>
        <div class="bar5"></div>
        <div class="bar6"></div>
      </div>
    </div>
  </div>
</div>
