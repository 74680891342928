import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FootnoteScroll } from '../../directives/footnotescroll.directive';
import { DateFormatPipe } from '../../pipes/dateFormat.pipe';
import { EscapeHtmlPipe } from '../../pipes/keep-html.pipe';
import { SafePipe } from '../../pipes/safe.pipe';

import { MenuComponent } from './menu.component';
import { ModalModule } from 'ngx-bootstrap/modal';
// Menu Module has all the common components to be used by all the other module's
@NgModule({
  declarations: [
    MenuComponent,
    SafePipe,
    EscapeHtmlPipe,
    FootnoteScroll,
    DateFormatPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    TranslateModule,
    Ng2SearchPipeModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
  ],
  exports: [
    MenuComponent,
    SafePipe,
    EscapeHtmlPipe,
    FootnoteScroll,
    TranslatePipe,
    DateFormatPipe,
  ],
})
export class MenuModule { }
