import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[footnotescroll]'
})
// tslint:disable-next-line:directive-class-suffix
export class FootnoteScroll {

  // tslint:disable-next-line:no-input-rename
  @Input('footnotescroll') public dynamicContent: string;

  constructor(private el: ElementRef) { }

  @HostListener('click', ['$event'])
  public onClick($event) {
    if ($event.target.classList.contains('edtw-reference-link' || 'edtw-conversion-link')) {
      const link: string = $event.target.hash;
      const url: string = $event.target.href;
      $event.preventDefault();
      $event.stopPropagation();

      window.location.hash = link;
      if (link) {
        const element = document.querySelector(link);
        // tslint:disable-next-line:curly
        if (element) element.scrollIntoView();
      } else if (url) {
        window.open(url, '_blank');
      }
    }
  }
}
